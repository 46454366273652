<template>
    <modal ref="modalCrearTarea" titulo="Crear tarea" tamano="modal-lg" adicional="Crear" @adicional="crearTarea">
        <div class="row justify-content-center mx-3 f-15 mt-3">
            <div class="col-12 my-2">
                <p class="input-label-top">Nombre de la tarea</p>
                <el-input v-model="inputNombreTarea" placeholder="Nombre de la tarea" size="small" class="w-100" />
            </div>
            <div class="col-6 my-2">
                <p class="input-label-top">Tipo de actividad</p>
                <el-select v-model="valueSelect" placeholder="Seleccionar tipo" size="small" class="w-100">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </div>
            <div class="col-6 my-2">
                <p class="input-label-top">Subtipo de actividad</p>
                <el-select v-model="valueSelect" placeholder="Seleccionar subtipo " size="small" class="w-100">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </div>
        </div>
        <div class="row justify-content-center mx-3 f-15 mb-3">
            <div class="col-6 my-2">
                <p class="input-label-top">Fecha</p>
                <el-date-picker v-model="inputNombreTarea" type="date" placeholder="Seleccionar fecha" size="small" class="w-100" />
            </div>
            <div class="col-3 my-2">
                <p class="input-label-top">Hora Inicio</p>
                <el-input v-model="inputHoraInicio" placeholder="Hola Inicio" size="small" class="w-100" />
            </div>
            <div class="col-3 my-2">
                <p class="input-label-top">Hora fin</p>
                <el-input v-model="inputHoraFin" placeholder="Hola Fin" size="small" class="w-100" />
            </div>
            <div class="col-12 my-2">
                <p class="input-label-top">Comentario</p>
                <el-input v-model="inputComentario" type="textarea" placeholder="Comentario" class="w-100" />
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            options: [
                {
                    value: 'Option1',
                    label: 'Option1'
                }, 
                {
                    value: 'Option2',
                    label: 'Option2'
                }, 
                {
                    value: 'Option3',
                    label: 'Option3'
                }, 
                {
                    value: 'Option4',
                    label: 'Option4'
                }, 
                {
                    value: 'Option5',
                    label: 'Option5'
                }
            ],
            valueSelect: '',
            inputNombreTarea: '',
            inputHoraInicio: '',
            inputHoraFin: '',
            inputComentario: '',
        }
    },
    methods: {
        toggle(){
            this.$refs.modalCrearTarea.toggle()
        },
        crearTarea(){
            this.toggle()
        }
    }
}
</script>

<style lang="scss" scoped>
.indicador-mint{
    border: 1px solid #3CD273;
    color: var(--color-5d);
    border-radius: 4px;
    height: 32px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    .item{
        background: #3CD273;
        border-radius: 0px 1px 1px 0px;
        padding: 0px 10px;
        display: flex;
        align-items: center;
        color: #fff;
    }
}
</style>
